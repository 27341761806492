import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function Copyright(props) {
  return (
    <Typography variant="caption" color="text.secondary" align="center" {...props}>
      {'© '}{new Date().getFullYear()}{' '}
      <Link style={{textDecoration: 'none'}} color="inherit">
        MyKin Journal, LLC &middot;&nbsp;
      </Link>
      <Link style={{textDecoration: 'none'}} color="inherit">
        Terms &middot; Privacy
      </Link>
    </Typography>
  );
}

const theme = createTheme();

export default function Landing() {

  const [authType, setAuthType] = useState('login');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/my-kin-journal.appspot.com/o/journal.jpg?alt=media&token=c47b6595-f0ae-4641-a6bd-6511b0f75720)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography style={{fontFamily: 'helvetica', fontWeight: '700'}} component="h1" variant="h5" color="#499506">
              MyKin<span style={{fontWeight: '100'}}>Journal</span>
            </Typography>
            <Typography variant="caption" sx={{width: 300, textAlign: 'center'}}>
              We send questions that help you learn more about your family via text-based micro journaling.
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                size="small"
                autoFocus
              />
              {authType === 'registration' && <TextField
                margin="dense"
                required
                fullWidth
                id="email"
                label="Confirm Email"
                name="email"
                autoComplete="email"
                size="small"
                autoFocus
              />}
              <TextField
                margin="dense"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                size="small"
              />
              {authType === 'registration' && <TextField
                margin="dense"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                autoComplete="current-password"
                size="small"
              />}
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{background: "#499506"}}
              >
                {authType === 'login' ? 'LOG IN' : 'SIGN UP'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" sx={{color: '#499506'}}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  {authType === 'login' && <Link onClick={() => setAuthType('registration')} href="#" variant="body2" sx={{color: '#499506'}}>
                    {"Need an account? SIGN UP"}
                  </Link>}
                  {authType === 'registration' && <Link onClick={() => setAuthType('login')} href="#" variant="body2" sx={{color: '#499506'}}>
                    {"Already have an account? LOG IN"}
                  </Link>}
                </Grid>
              </Grid>
              <Copyright sx={{display: 'block', mt: 5, fontFamily: 'helvetica', fontWeight: '500', width: '100%', textAlign: 'center' }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}