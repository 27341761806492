import Landing from './views/landing';


function App() {
  return (
    <div>
      <Landing />
    </div>
  );
}

export default App;
